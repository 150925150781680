.account {
    padding: 40px 16px;
    height: auto;

    .info-block {
        margin-top: 30px;
        border-radius: 4px;
        padding: 20px 10px;

        article {
            .article-title {
                font-weight: 500;
                font-size: 30px;
                line-height: 19px;
                margin: 30px 0;
                color: $primaryColor;
            }

            .content {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                div {
                    margin: 1rem 0.5rem;

                    h5 {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 14px;
                        text-transform: uppercase;
                    }

                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-top: 8px;

                        &.name,
                        &.plan,
                        &.address,
                        &.gender {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}

@include for-phone-only {
    .account {
        padding: $smallestBodyPadding;

        .info-block {
            article {
                .article-title {
                    font-size: 24px;
                }
            }
        }
    }
}

@include for-tablet-potrait-up {
    .account {
        .info-block {
            article {
                .article-title {
                    font-size: 40px;
                    text-align: start;
                }

                .content {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .account {
        padding: $desktopBodyPadding;

        .info-block {
            padding: 50px 30px;

            article {
                .article-title {
                    font-size: 40px;
                }

                .content {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}

@include for-big-desktop-up {}