.about {
    h1 {
        font-weight: 700;
        font-size: 30px;
        color: #5741d9;
        margin-bottom: 25px;
        padding: $mobileBodyPadding;

        span {
            font-weight: 300;
            color: $darkText;
        }
    }

    .block {
        margin-bottom: 30px;
        padding: $mobileBodyPadding;

        h2 {
            color: $primaryColor;
            font-weight: 700;
            font-size: 23px;
            margin-bottom: 10px;
            line-height: 30px;

            span {
                color: $darkText;
                font-style: italic;
                font-weight: 200;
            }
        }

        h4 {
            font-weight: 600;
            margin: 20px 0 10px 0;
        }

        p {
            font-weight: 400;
            line-height: 28px;

        }
    }


}





@include for-phone-only {}

@include for-tablet-potrait-up {
    .about {
        .block {
            margin-bottom: 50px;

            h2 {
                font-size: 28px;
                margin-bottom: 15px;
            }


        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .about {
        padding: $desktopBodyPadding;

        h1 {
            font-size: 55px;
        }
    }
}

@include for-big-desktop-up {}