@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import "./variables";
@import "./mixins";

// COMPONENTS
@import '../components/navbar/navbar';
@import '../components/footer/footer';
@import '../components/loaders/loaders';
@import '../components/modals/modals';
@import '../components/wallet/wallet';
@import '../components/withdrawal/withdrawal-methods';


// PAGES
@import './home';
@import '../pages/prices/prices';
@import '../pages/about/about';
@import '../pages/services/services';
@import '../pages/contact/contact';
@import '../pages/sign-in/sign-in';
@import '../pages/sign-up/sign-up';
@import '../pages/terms-of-use/terms-of-use';
@import '../pages/privacy-policy/privacy';
@import '../pages/guides/guides';

// USER DASHBOARD
@import '../pages/dashboard/dashboard';
@import '../pages/dashboard/account';
@import '../pages/dashboard/upgrade-account';
@import '../pages/dashboard/deposit';
@import '../pages/dashboard/history';
@import '../pages/dashboard/withdrawal';




* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    color: $darkText;
    font-weight: 300;
}

a {
    color: inherit;
    text-decoration: none;
}


ul {
    list-style-type: none;
}


img {
    width: 100%;
    height: 100%;
}


p {
    line-height: 28px;
    font-weight: 400;
}

// buttons

button {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
    padding: .8rem;
    cursor: pointer;
    transition: 0.2s linear opacity;

    &.white {
        background: $whiteColor;
        color: $primaryColor;
    }

    &.purple {
        background: $primaryColor;
        color: $whiteColor;
    }

    &.grey {
        background: #EAECEF;
        color: $darkText;
        border: none;

        &:hover {
            background-color: #F5F5F5;
        }
    }


    &:hover {
        opacity: 0.5;
    }
}

.link {
    text-decoration: underline;
    color: $primaryColor;
}

// CUSTOM INPUT FIELDS 

.label {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 0 4px;
    color: #7E7E7E;
    background: $whiteColor;
    transition: 0.5s;
    z-index: 0;

    &.navOpen {
        display: none;
        z-index: -4;
    }
}

.input:focus+.label {
    top: -10px;
    left: 12px;
    z-index: 10;

}

.input:not(:placeholder-shown)+.label {
    top: -10px;
    left: 12px;
    z-index: 10;
}

.input {
    width: 100%;
    height: 100%;
    border: 1px solid #D0CFCF;
    border-radius: 4px;
    background: transparent;
    padding: 16px;
    font-size: 16px;
    background: none;
    outline: none;
    position: absolute;
    top: 0px;
    left: 0px;



    &::placeholder {
        color: transparent;
    }

    &.red {
        border: 1px solid #CB3D40;
    }

}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $whiteColor inset !important;
}


// RESET INPUT FONT-SIZE FOR MOBILE
@media screen and (max-width: 767px) {

    input,
    select,
    textarea {
        font-size: 16px;
    }
}


// COPY TO CLIPBOARD

.copy {
    padding: .1rem .5rem;
    border-radius: 5px;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: $generalTransition;

    &:hover {
        transform: scale(1.2);
    }

    &-btn {
        &.copied {
            color: $primaryColor;
        }
    }

}

@include for-tablet-potrait-up {
    .copy {
        position: absolute;
        right: 5px;
    }
}