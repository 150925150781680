.home {

    .block {
        @include flexCB;
        background: $greyBackground;
        flex-direction: column;
        padding: $mobileBodyPadding;

        .text {
            width: 100%;

            h1 {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 15px;
                text-align: center;
            }

            h2 {
                font-size: 25px;
                margin-bottom: 15px;
                color: $primaryColor;

                span {
                    font-weight: 300;
                    color: $darkText;
                }
            }

            h5 {
                font-size: 17px;
                font-weight: 500;
                margin: 5px 0;
            }


            .header-p {
                text-align: center;
                line-height: 36px;
            }

            .btn {
                @include flexCC;

                button {
                    min-width: 200px;
                    margin: 20px 0;
                    padding: 15px;
                }

            }
        }

        &.everything-you-need {
            background: $whiteColor;
        }


        .image {
            position: relative;
            width: 100%;
            height: 40vh;
            margin-bottom: 25px;
        }


        .video {
            width: 100%;
            height: 60vh;

            video {
                width: 100%;
                height: 100%;
            }
        }
    }

    .get-started,
    .whats-next {
        padding: $mobileBodyPadding;
        padding-top: 40px;
        padding-bottom: 40px;

        h2 {
            font-size: 30px;
            width: 100%;
            margin-bottom: 25px;
            color: $primaryColor;
        }

        h4 {
            font-size: 20px;
            font-weight: 400;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
            margin-top: 25px;

            .card {
                background: $primaryColor;
                filter: drop-shadow(0px 10px 20px rgba(41, 41, 42, 0.07));
                padding: 70px 10px;
                text-align: center;
                border-radius: 8px;
                color: $whiteColor;
                font-size: 20px;
                text-decoration: underline;
                transition: $generalTransition;
            }
        }
    }

    .security {
        background: $whiteColor;
    }

    .testimonies {
        @include flexCC;
        flex-direction: column;
        padding: $mobileBodyPadding;
        background: $greyBackground;

        h2 {
            font-size: 35px;
            color: $primaryColor;
        }

        h4 {
            font-size: 18px;
            font-weight: 500;
        }

        .customers {
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;

            .customer {
                height: auto;
                @include flexCC;
                flex-direction: column;
                text-align: center;

                .profile-img {
                    height: 150px;
                    width: 150px;
                    border-radius: 50%;
                    @include flexCC;
                    position: relative;

                    .img {
                        border-radius: 50%;
                        object-fit: cover;
                        align-self: center;
                    }
                }

                h4 {
                    margin: 10px 0;
                }
            }
        }
    }
}

@include for-phone-only {
    .home {

        .block {
            padding: $smallestBodyPadding;

            .text {
                h1 {
                    font-size: 25px;
                }

                h2 {
                    font-size: 22px;
                }

                h5 {
                    font-size: 1rem;
                }
            }
        }

        .get-started,
        .whats-next {
            padding: $smallestBodyPadding;

            h2 {
                font-size: 25px;
            }

            h4 {
                font-size: 20px;
            }

            .cards {
                grid-template-columns: repeat(1, 1fr);

            }
        }

        .testimonies {
            padding: $smallestBodyPadding;

            h2 {
                font-size: 25px;
            }

            h4 {
                font-size: 16px;
            }

            .customers {

                .customer {
                    .profile-img {
                        height: 110px;
                        width: 110px;
                    }
                }
            }
        }
    }
}

@include for-tablet-potrait-up {
    .home {

        .block {
            flex-direction: row;
            padding-top: 50px;
            padding-bottom: 50px;

            .text {
                h1 {
                    font-size: 40px;
                    text-align: start;
                }

                h5 {
                    font-size: 20px;
                    text-align: start;
                }

                .header-p {
                    text-align: start;
                }

                .btn {
                    justify-content: flex-start;
                }
            }
        }

        .get-started,
        .whats-next {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                font-size: 35px;
                width: 80%;
            }

            h4 {
                font-size: 25px;
            }

            .cards {
                grid-template-columns: repeat(2, 1fr);

                .card {
                    font-size: 25px;
                }
            }
        }

        .testimonies {
            h2 {
                font-size: 40px;
            }

            h4 {
                font-size: 20px;
            }

            .customers {
                grid-template-columns: repeat(2, 1fr);

                .customer {

                    &:last-child {
                        grid-column: 1/3;
                        padding: 0 20%;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .home {

        .block {
            padding: $desktopBodyPadding;

            .text {
                h1 {
                    font-size: 60px;
                    line-height: 60px;
                    margin-bottom: 25px;
                    text-align: start;
                }

                h2 {
                    font-size: 40px;
                    margin-bottom: 15px;
                }

                h5 {
                    font-size: 26px;
                    text-align: start;
                    margin: 5px 0;
                }

                .header-p {
                    text-align: start;
                    font-size: 25px;
                }
            }

            .image {
                height: 70vh;
            }

            .video {
                height: 80vh;
            }

            &.sec {
                padding: 40px 100px;
            }
        }

        .get-started,
        .whats-next {
            padding: $desktopBodyPadding;

            h2 {
                font-size: 40px;
                width: 60%;
            }

            h4 {
                font-size: 20px;
            }

            .cards {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 20px;

                .card {
                    font-size: 25px;

                    &:hover {
                        opacity: 0.85;
                    }
                }
            }
        }

        .testimonies {
            h2 {
                font-size: 40px;
            }

            h4 {
                font-size: 20px;
            }

            .customers {
                grid-template-columns: repeat(3, 1fr);

                .customer {
                    .profile-img {
                        height: 150px;
                        width: 150px;
                    }

                    &:last-child {
                        grid-column: auto;
                        padding: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@include for-big-desktop-up {}